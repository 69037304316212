export default {
    nav: {
        main: {
            assets: 'Stamgegevens',
            planning: 'Planning',
            orders: 'Orders',
        },
        assets: {
            users: 'Gebruikers',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
            emailTemplates: 'Email sjablonen',
            customers: 'Klanten',
            crops: 'Planten',
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        planning: {
            packagingLines: 'Verpakkingslijnen',
            main: 'Planning',
            salesOrders: 'Orders',
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Nieuwe Gebruiker',
        },
        create: {
            title: 'Gebruiker Aanmaken',
        },
        edit: {
            title: 'Gebruiker Aanpassen',
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: { label: 'Taal' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    manager: 'Manager',
                    team_lead: 'Team lead',
                    planner: 'Planner',
                    operator: 'Operator',
                    viewer: 'Viewer',
                },
                empty: 'Geen groepen toegekend',
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
            mfa: {
                label: 'MFA-methoden',
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'E-mail of wachtwoord is niet correct',
                unknown: 'Onbekende fout, status code: {{status}}',
            },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord Vergeten',
            requestButton: 'Verstuur Email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord Reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord Aanpassen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Profiel',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Gegevens',
            },
            password: {
                title: 'Wachtwoord Veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet u zeker dat u wilt uitloggen?'
            },
        },
        multiFactorAuth: {
            code: {
                app: 'Verificatiecode',
                email: 'Inlogcode',
                newCode: 'Nieuwe code aanvragen',
            },
            expiring: 'Je authenticatie code is nog {{ minutes }} minuten geldig',
            explanation: {
                app: 'Voer de verificatiecode in die uw verificatie-app voor u heeft gegenereerd.',
                email: 'Uw inlogcode is per e-mail naar u verzonden.',
            },
            explanationFailed: 'We hebben geprobeerd een inlogcode naar het geregistreerde emailadres te sturen, maar dit is mislukt.',
            timeout: 'U hebt het te vaak geprobeerd. U kunt het over een paar minuten opnieuw proberen.',
            invalid: {
                app: 'De verificatiecode is verkeerd of is verlopen. Probeer het opnieuw.',
                email: 'De inlogcode is verkeerd of is verlopen. Probeer het opnieuw of vraag een nieuwe code aan.',
            },
            resend: 'Verstuur opnieuw',
            title: 'Bevestig',
        },
        permissions: {
            noEditPermissions: 'Waarschuwing: U heeft geen rechten om te bewerken',
        },
    },
    globalValue: {
        edit: {
            title: 'Waarde wijzigen van algemene instelling',
        },
        overview: {
            title: 'Algemene instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Nieuw',
        action: {
            unsetDefault: 'Gebruik niet als standaard',
            setDefault: 'Gebruik als standaard',
            setCurrent: 'Sla huidige filters op',
            delete: 'Verwijderen',
        },
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore: 'Herstel',
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        saveButton: 'Opslaan',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Geen geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Niet geauthorizeerd',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        submitButton: 'Verstuur',
        cancelButton: 'Annuleren',
        addButton: 'Toevoegen',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
        no: 'Nee',
        yes: 'Ja',
        either: 'Beide',
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    emailTemplate: {
        overview: {
            title: 'Email sjablonen',
            addButton: 'Nieuw sjabloon',
        },
        create: { title: 'Mail sjabloon aanmaken' },
        edit: { title: 'Mail sjabloon aanpassen' },
        add: { title: 'Mail sjabloon toevoegen' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is standaard' },
            name: { label: 'Naam' },
            emailType: { label: 'Email type' },
            subject: { label: 'Onderwerp' },
            textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
            senderEmail: { label: 'Verzender' },
        }
    },
    emailTypes: {
        password_reset: 'Wachtwoord reset',
        user_activation: 'Account activatie',
    },
    authentication: {
        dates: {
            maxDate: {
                placeholder: 'Max date'
            },
            minDate: {
                placeholder: 'Min date'
            },
            month: {
                0: 'January',
                1: 'February',
                10: 'November',
                11: 'December',
                2: 'March',
                3: 'April',
                4: 'May',
                5: 'June',
                6: 'July',
                7: 'August',
                8: 'September',
                9: 'October'
            },
            weekDayShort: {
                friday: 'Fr',
                monday: 'Mo',
                saturday: 'Sa',
                sunday: 'Su',
                thursday: 'Th',
                tuesday: 'Tu',
                wednesday: 'We'
            }
        },
        field: {
            mfa: {
                label: 'Methode',
            },
            code: {
                label: 'Code'
            },
            expiresAt: {
                label: 'Verloopt Op'
            },
            ipAddress: {
                label: 'IP-adres'
            },
            status: {
                label: 'Status',
                value: {
                    expired: 'Verlopen',
                    verified: 'Geverifieerd',
                    waiting: 'In behandeling'
                }
            },
            userAgent: {
                label: 'User Agent'
            }
        }
    },
    multiFactorAuthentication: {
        setup: {
            title: 'Multistapsverificatie instellen',
            step1: {
                title: '1. Download de app',
                description: 'Download een verificatie-app, zoals Google Authenticator, als u deze nog niet heeft.',
            },
            step2: {
                title: '2. Scan de QR-code',
                description: 'Scan deze QR-code met uw verificatie-app.',
            },
            step3: {
                title: '3. Voer uw code in',
                description: 'Voer hier de bevestigingscode in die u ziet in uw verificatie-app.',
            },
            subtitle: 'Na het instellen kunt u met uw verificatie-app altijd veilig inloggen.',
        },
        field: {
            method: {
                label: 'MFA-methode',
                options: {
                    app: 'Verificatie-app',
                    email: 'E-mail'
                }
            },
            counter: {
                label: 'Gebruik',
            },
            activated: {
                label: 'Geverifieerd',
            },
        }
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
    color: {
        yellow: 'Geel',
        orange: 'Oranje',
        darkorange: 'Donkeroranje',
        lightred: 'Lichtrood',
        red: 'Rood',
        darkred: 'Donkerrood',
        lightpink: 'Lichtroze',
        pink: 'Roze',
        darkpink: 'Donkerroze',
        lightviolet: 'Lichtviolet',
        violet: 'Violet',
        darkviolet: 'Donkerviolet',
        lightpurple: 'Lichtpaars',
        purple: 'Paars',
        darkpurple: 'Donkerpaars',
        lightindigo: 'Lichtindigo',
        indigo: 'Indigo',
        darkindigo: 'Donkerindigo',
        lightblue: 'Lichtblauw',
        blue: 'Blauw',
        darkblue: 'Donkerblauw',
        lightteal: 'Licht blauwgroen',
        teal: 'Blauwgroen',
        darkteal: 'Donker blauwgroen',
        lightgreen: 'Lichtgroen',
        green: 'Groen',
        darkgreen: 'Donkergroen',
        lightlime: 'Licht geelgroen',
        lime: 'Geelgroen',
        darklime: 'Donker geelgroen',
        lightbrown: 'Lichtbruin',
        brown: 'Bruin',
        darkbrown: 'Donkerbruin',
        lightgrey: 'Lichtgrijs',
        grey: 'Grijs',
        black: 'Zwart',
        none: 'Geen kleur',
    },
    customer: {
        overview: {
            title: 'Klanten',
        },
        edit: {
            title: 'Bewerk klant',
            tabs: {
                templates: 'Order templates',
                packagingSizes: 'Toegewezen planten',
            },
        },
        create: {
            title: 'Maak klant aan',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            color: {
                label: 'Kleur',
            },
            orderTemplates: {
                label: 'Order templates',
                singular: 'Order template',
            },
            hasQualityMark: {
                label: 'Heeft keurmerk?',
            },
            qualityMarkName: {
                label: 'Keurmerk naam',
            },
            assignedPackagingSizes: {
                label: 'Toegewezen planten',
            },
        },
    },
    crop: {
        overview: {
            title: 'Planten',
        },
        edit: {
            title: 'Bewerk plant',
            packagingSizes: {
                addButtonLabel: 'Maak verpakkingsgrootte aan',
                removeButtonLabel: 'Verwijder verpakkingsgrootte',
            },
        },
        create: {
            title: 'Maak plant aan',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            packagingSizes: {
                label: 'Verpakkingsgroottes'
            },
        },
    },
    packagingSize: {
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            productionRate: {
                label: 'Aantal geproduceerd per uur',
                shortLabel: 'aantal / uur',
            },
            amountPerCrate: {
                label: 'Aantal per kist',
                shortLabel: 'aantal / kist',
            },
            order: {
                label: 'Volgorde',
            },
        },
    },
    packagingLine: {
        overview: {
            title: 'Verpakkingslijnen',
        },
        edit: {
            title: 'Bewerk verpakkingslijn',
        },
        create: {
            title: 'Maak verpakkingslijn aan',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            customer: {
                label: 'Klant',
            },
        },
    },
    salesOrder: {
        overview: {
            title: 'Orders',
            productionOrderProgress: {
                label: 'Voltooide artikelen',
                filterLabel: 'Voltooid',
                options: {
                    yes: 'Voltooid',
                    no: 'Niet voltooid',
                    both: 'Beide',
                },
            },
            buttons: {
                goToPlanning: 'Bezoek in planning',
            },
        },
        edit: {
            title: 'Bewerk order',
            pickupTimes: {
                addButtonLabel: 'Voeg pick-up tijd toe',
                removeButtonLabel: 'Verwijder pick-up tijd',
            },
            productionOrders: {
                addButtonLabel: 'Voeg artikel toe',
                removeButtonLabel: 'Verwijder artikel',
            },
        },
        import: {
            importModal: {
                title: 'Selecteer een dag voor de Bakker Excel import',
                description: 'Selecteer welke dag van de week u wilt importeren in uw geopende order.',
                actions: {
                    confirm: 'Bevestig dag',
                },
            },
            importButton: 'Importeer order (Bakker)',
        },
        create: {
            title: 'Nieuwe order',
        },
        field: {
            id: {
                label: 'ID',
            },
            deleted: {
                label: 'Verwijderd',
            },
            orderId: {
                label: 'Order ID',
            },
            customer: {
                label: 'Klant',
            },
            date: {
                label: 'Pick-up datum',
            },
            productionOrders: {
                label: 'Artikelen',
            },
            pickupTimes: {
                label: 'Pick-up tijden',
                shortLabel: 'Pick-up',
            },
            orderDocuments: {
                label: 'Order documenten'
            },
            remarks: {
                label: 'Notities',
            },
        },
    },
    productionOrder: {
        field: {
            quantity: {
                label: 'Hoeveelheid',
                shortLabel: 'stuks',
                miniLabel: '# P',
                helper: 'Aantal stuks (pieces)',
            },
            // NOTE: Not a real field, annotation
            quantityCrates: {
                label: 'Aantal kisten',
                shortLabel: 'kisten',
                miniLabel: '# B',
                helper: 'Aantal kisten (boxes)',
            },
            article: {
                label: 'Article',
            },
            time: {
                label: 'Time',
            },
            palletNumber: {
                label: 'Pallet nummer',
                shortLabel: 'Plt num',
                tableLabel: 'Pallet',
            },
        },
    },
    planning: {
        overview: {
            salesOrders: {
                createButton: 'Nieuwe order',
                editButton: 'Bewerk',
                closeButton: 'Sluit',
                deleteConfirmation: 'Dit zal de sales order voor eeuwig verwijderen (een hele lange tijd). Weet je het zeker?',
                totalTime: 'Totale tijd',
                stillToPlan: 'Nog te plannen',
                stillToComplete: 'Nog te voltooien',
            },
            splitProductionOrderModal: {
                title: 'Splits productie order',
                actions: {
                    split: 'Split productie order',
                },
            },
        },
    },
    document: {
        overview: {
            title: 'Documenten',
            addButton: 'Document',
            downloadButton: 'Download'
        },
        edit: {
            title: 'Document bewerken',
        },
        create: {
            title: 'Nieuw document',
        },
        field: {
            id: {
                label: 'Document nr.'
            },
            type: {
                label: 'Bestandsformaat',
            },
            file: {
                label: 'Bestand',
            },
            path: {
                label: 'Path',
            },
        },
        preview: {
            noFileToPreview: 'Geen preview beschikbaar',
            openFilePreview: 'Open bestandspreview',
            closeFilePreview: 'Bestandspreview sluiten',
        },
        input: {
            upload: {
                label: 'Bestand uploaden',
            },
            download: {
                label: 'Bestand downloaden'
            },
        },
    },
    orderTemplate: {
        overview: {
            addLabel: 'Nieuwe order template'
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            orderTemplateLines: {
                label: 'Order template lijnen',
                addButtonLabel: 'Voeg order template lijn toe',
                removeButtonLabel: 'Verwijder order template lijn',
            },
        },
        create: {
            title: 'Nieuwe order template'
        },
        edit: {
            title: 'Bewerk order template',
            orderTemplateLines: {
                addButtonLabel: 'Voeg order template lijn toe',
                removeButtonLabel: 'Verwijder order template lijn',
            },
            warnings: {
                noEditPermissions: 'Je hebt geen rechten om order templates te maken of aan te passen',
                noCustomer: 'Sla deze nieuwe klant op voordat je een order template toevoegt',
            },
        },
    },
    planningItem: {
        edit: {
            title: 'Bewerk planning item',
        },
        field: {
            name: {
                label: 'Naam',
            },
        },
    },
};
